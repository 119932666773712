import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      form: null,
    }
  },
  mutations: {
    setForm(state, payload) {
      state.form = payload;
    },
  },
  actions: {
    loadForm({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/form').then((response) => {
          commit('setForm', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
  },
  getters: {
    getForm: (state) => state.form,
  }
}