import auth from "../middlewares/auth";
export const formRoutes = [
  {
    path: '/forms',
    component: () => import(/* webpackChunkName: "forms" */ '../views/form/FormList'),
    name: 'forms',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/forms/:intake/:note/:name/:template/:template_name/:note_template/:note_template_name',
    component: () => import(/* webpackChunkName: "forms" */ '../views/form/FormPreview'),
    name: 'form_preview',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/forms/add',
    component: () => import(/* webpackChunkName: "forms" */ '../views/form/FormForm'),
    name: 'forms_add',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/forms/edit/:id',
    component: () => import(/* webpackChunkName: "forms" */ '../views/form/FormForm'),
    name: 'forms_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/forms/view/:id',
    component: () => import(/* webpackChunkName: "forms" */ '../views/form/FormDetails'),
    name: 'forms_view',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/forms/responses',
    component: () => import(/* webpackChunkName: "forms" */ '../views/form/Response'),
    name: 'forms_responses',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];