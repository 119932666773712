<template>
  <!-- Top Bar Start -->
  <div class="topbar">
    <!-- Navbar -->
    <nav class="navbar-custom">
      <ul class="list-unstyled topbar-nav float-end mb-0">
        <li class="dropdown" v-if="user">
          <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown" href="#" role="button"
             aria-haspopup="false" aria-expanded="false">
            <span class="ms-1 nav-user-name hidden-sm">{{ user.name }}</span> <img :src="user.avatar ? user.avatar : '/assets/images/default_user.png'" @error="$event.target.src='/assets/images/default_user.png'"  alt="profile-user" class="rounded-circle thumb-xs" />
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" @click.prevent="settings" href="#">
              <i data-feather="settings" class="align-self-center icon-xs icon-dual me-1"></i> Profile
            </a>
            <a class="dropdown-item" @click.prevent="logout" href="#">
              <i data-feather="power" class="align-self-center icon-xs icon-dual me-1"></i> Logout
            </a>
          </div>
        </li>
      </ul><!--end topbar-nav-->
    </nav>
    <!-- end navbar-->
  </div>
  <!-- Top Bar End -->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  methods: {
    settings() {
      this.$emit('showSettingsModal')
    },
    logout() {
      this.$store.dispatch('auth/logout')
        .finally(() => {
          this.$router.push({name: 'login'})
        })
    }
  }
}
</script>