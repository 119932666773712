import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      settings: null,
    }
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload;
    },
  },
  actions: {
    saveSettings({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/settings', payload).then((response) => {
          commit('setSettings', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    loadSettings({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/settings').then((response) => {
          commit('setSettings', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
  },
  getters: {
    getSettings: (state) => state.settings,
  }
}