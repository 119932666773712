<template>
  <Teleport to="body">
    <template v-if="$route.name !== 'login'">
      <side-nav-component></side-nav-component>

      <div class="page-wrapper">
        <top-bar-component @showSettingsModal="showSettingsModal"></top-bar-component>

        <!-- Page Content-->
        <div class="page-content">
          <div class="container-fluid">
            <router-view/>
            <footer-component></footer-component>
          </div>
        </div>
      </div>

      <setting-modal ref="settingModal"></setting-modal>
    </template>

    <router-view v-else></router-view>
  </Teleport>
</template>

<script>
import SideNavComponent from "@/components/SideNavComponent";
import TopBarComponent from "@/components/TopBarComponent";
import FooterComponent from "@/components/FooterComponent";
import SettingModal from "@/components/SettingModal";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {FooterComponent, SideNavComponent, TopBarComponent, SettingModal},
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      settings: 'settings/getSettings',
    })
  },
  async created() {
    if (this.user) {
      await this.axios.get('/user')
        .then(async () => {
          await this.$store.dispatch('settings/loadSettings')
            .then(res => {
              let settings = res.data.data;

              if (!settings.clinic_address && !settings.clinic_email &&
                !settings.clinic_name && !settings.clinic_phone) {
                this.showSettingsModal();
              }
            });

          await this.$store.dispatch('form/loadForm').then(res => {
            if(res.data.data == null) {
              this.$router.replace({name: 'forms_add'})
            }
          });
        }).catch(() => {
        this.$store.dispatch('auth/resetUser');
        this.$router.push({name: 'login'});
      });
    }
  },
  mounted() {
    window.feather.replace();

    /*if (this.user) {
      setTimeout(() => {
        this.showSettingsModal();
      }, 500)
    }*/
  },
  methods: {
    showSettingsModal() {
      this.$refs.settingModal.show();
    }
  },
  watch: {
    $route() {
      if (this.$route.name === 'login') {
        document.body.classList.add('account-body')
        document.body.classList.add('accountbg')
      } else {
        document.body.classList.remove('account-body')
        document.body.classList.remove('accountbg')
      }
    }
  }
}
</script>
