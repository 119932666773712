import { createStore } from 'vuex'
import authModule from './modules/auth'
import settingsModule from './modules/settingsModule'
import formModule from './modules/formModule'

const store = createStore({
  state () {
    return {

    }
  },
  mutations: {

  },
  actions: {

  },
  getters: {
  },

  modules: {
    auth: authModule,
    settings: settingsModule,
    form: formModule,
  }
})

export default store