<template>
  <!-- Left Sidenav -->
  <div class="left-sidenav">
    <!-- LOGO -->
    <div class="brand">
      <router-link :to="{name: 'dashboard'}" class="logo">
        <span>
            <img :src="baseUrl + 'assets/images/logo.png'" alt="logo-small" class="logo-sm" style="height: 45px">
        </span>
        <span>
          <img :src="baseUrl + 'assets/images/logo.png'" alt="logo-large" class="logo-lg logo-light">
        </span>
      </router-link>
    </div>
    <!--end logo-->
    <div class="menu-content h-100" data-simplebar>
      <ul class="metismenu left-sidenav-menu">
        <li class="menu-label mt-0 text-center" v-if="form">
          <h6>{{ form.name }}</h6>
          <div v-if="form">
            <a :href="'https://drive.google.com/drive/folders/' + form.folder_id"
               target="_blank"
               class="text-info">Go to Google Drive Folder</a>
          </div>

<!--          <div class="d-grid gap-2">
            <router-link :to="{name: 'settings'}" class="btn btn-outline-primary">Edit Form</router-link>
          </div>-->
        </li>

        <li class="menu-label mt-0" v-else>
          <div class="d-grid gap-2">
            <router-link :to="{name: 'forms_add'}"
                         class="btn btn-outline-primary">Add A Practice</router-link>
          </div>
        </li>

        <hr>

        <li :class="{'active': $route.name === 'forms'}"  v-show="form">
          <router-link :to="{name: 'dashboard'}" active-class="active">
            <i data-feather="box" class="align-self-center menu-icon"></i><span>Dashboard</span>
          </router-link>
        </li>
        <li :class="{'active': $route.name === 'forms_responses'}" v-show="form">
          <router-link :to="{name: 'forms_responses'}" active-class="active">
            <i data-feather="message-circle" class="align-self-center menu-icon"></i><span>Clients</span>
          </router-link>
        </li>
        <li :class="{'active': $route.name === 'settings'}" v-show="form">
          <router-link :to="{name: 'settings'}" active-class="active">
            <i data-feather="settings" class="align-self-center menu-icon"></i><span>Settings</span>
          </router-link>
        </li>
        <!-- <li class="text-danger" v-show="form">
          <a href="#"
             @click="$refs.deleteModal.show()"
             class="text-danger">
            <i data-feather="trash" class="align-self-center menu-icon text-danger"></i><span>Delete</span>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
  <!-- end left-sidenav-->

</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      form: "form/getForm"
    })
  },
  mounted() {
    window.$(".metismenu").metisMenu();
    window.$(".button-menu-mobile").on("click", function (a) {
      a.preventDefault(), window.$("body").toggleClass("enlarge-menu");
    });
    window.$(".main-icon-menu .nav-link").on("click", function (a) {
      window.$("body").removeClass("enlarge-menu");
      a.preventDefault();
      window.$(this).addClass("active");
      window.$(this).siblings().removeClass("active");
      window.$(".main-menu-inner").addClass("active");

      let t = window.$(this).attr("href");
      window.$(t).addClass("active")
      window.$(t).siblings().removeClass("active");
    })
  }
}
</script>
<style>
</style>